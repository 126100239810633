<template>
  <div class="billreport">
    <div class="title">
      <h2>Direct Report</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :model="directReport" :inline="true">
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Offer(s)</label>
                </el-col>
                <el-form-item label="OfferId(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="directReport.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="OfferName" label-width="2rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="directReport.offerName"
                      placeholder="OfferName: eg. Mobile Strike"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Am(s)</label>
                </el-col>
                <el-form-item label-width="2.08rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-select v-model="directReport.am" style="width:100%" multiple clearable>
                      <el-option
                        v-for="item in amList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="amChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
                <el-form-item label-width="2.5rem" label="AllAffiliate" v-if="showAllAff">
                  <el-switch
                    v-model="directReport.isAllAff"
                    :active-value="1"
                    :inactive-value="0"
                  />
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Source(s)</label>
                </el-col>
                <el-form-item label="Source(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="12" :md="12" :lg="10" :xl="12">
                    <group-select
                      v-model="directReport.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                      style="width:3.95rem;"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="sourceChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :lg="2" :md="2" :xs="24" :sm="2" :xl="2">
                  <label class="labelName">Target(s)</label>
                </el-col>
                <el-form-item label="Countries" label-width="1.8rem">
                  <el-col :lg="6" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-input
                      v-model="directReport.geo"
                      style="width:2.4rem;"
                      placeholder="eg. US,IT"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="countriesChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
                <el-form-item label="Package" label-width="1.2rem">
                  <el-col :lg="7" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-input
                      v-model="directReport.pkg"
                      style="width:2.5rem;"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="packageChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:20px;">TimeZone</label>
                <el-form-item>
                  <el-select v-model="directReport.timezone" style="width:420px;">
                    <el-option
                      v-for="item in timezoneOption"
                      :key="item.value"
                      :value="item.number"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName">Interval</label>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Month">
                    <el-checkbox v-model="monthChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Date">
                    <el-checkbox v-model="dateChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Hour">
                    <el-checkbox v-model="hourChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:60px;">Date</label>
                <el-form-item label="Date">
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="PdDate">
                  <el-date-picker
                    v-model="pdStartToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="8" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="exportExcelFileClick">
                      Download Excel
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              ref="tableRef"
              style="width:100%;"
              height="70vmin"
              stripe
              border
              :data="directReportList"
              highlight-current-row
            >
              <el-table-column
                label="Pkg"
                prop="pkg"
                fixed="left"
                align="center"
                width="170"
                key="pkg"
                v-if="packageChecked"
              ></el-table-column>
              <el-table-column
                label="Am"
                :fixed="fullWidth <= 768 ? false : 'left'"
                min-width="100"
                align="center"
                key="am"
                prop="am"
                v-if="amChecked"
              >
              </el-table-column>
              <el-table-column
                label="Source ID"
                v-if="sourceChecked"
                width="100"
                align="center"
                key="sourceId"
              >
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                    scope.row.sourceId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Source Name"
                width="140"
                align="center"
                v-if="sourceChecked"
                key="sourceName"
              >
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                    scope.row.sourceName
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Offer ID"
                align="center"
                v-if="offerChecked"
                width="110"
                key="offerId"
              >
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                    scope.row.offerId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Offer Name"
                v-if="offerChecked"
                width="140"
                align="center"
                show-overflow-tooltip
                key="offerName"
              >
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                    scope.row.offerName
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Offer Status"
                prop="offerStatus"
                width="130"
                align="center"
                v-if="offerChecked"
                key="offerStatus"
              ></el-table-column>
              <el-table-column label="Po" prop="po" width="100" align="center"></el-table-column>
              <el-table-column label="Cap" prop="cap" width="100" align="center"></el-table-column>
              <el-table-column
                label="DateStart"
                prop="dateStart"
                width="180"
                align="center"
              ></el-table-column>
              <el-table-column
                label="DatePause"
                prop="datePause"
                width="180"
                align="center"
              ></el-table-column>
              <el-table-column
                label="Total Revenue"
                min-width="180"
                prop="runningWaterPo"
                align="center"
              >
              </el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageParam.pageSize"
              :currentPage="pageParam.page"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { api, getAmListApi, getDirectReport } from 'api/report';

  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import { cloneDeep } from 'lodash-es';
  import dateUtils from '@/utils/dateutils.js';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { filterObject } from 'utils/utils';
  import { mapActions, mapState } from 'vuex';
  import qs from 'querystring';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        amList: [],
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        dataParam: null,
        offerChecked: true,
        sourceChecked: false,
        fullWidth: document.documentElement.clientWidth,
        packageChecked: true,
        countriesChecked: true,
        monthChecked: false,
        dateChecked: true,
        hourChecked: true,
        amChecked: true,
        directReportList: [],
        total: 0,
        listLoading: false,
        summariesMap: {},
        startToEndDate: [],
        pdStartToEndDate: [],
        timezoneOption: optionData.timezoneOption,
        showAllAff: false,
        directReport: {
          geo: null,
          pkgs: null,
          sourceIds: null,
          offerIds: null,
          offerName: null,
          dateStart: null,
          dateEnd: null,
          am: null,
          pbDateStart: null,
          pbDateEnd: null,
          timezone: 0,
          groupMarks: ['dateStart'],
          isAllAff: 0,
        },
      };
    },
    computed: {
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
      listenChange() {
        const { amChecked, sourceChecked, offerChecked, packageChecked, countriesChecked } = this;
        return {
          amChecked,
          sourceChecked,
          offerChecked,
          packageChecked,
          countriesChecked,
        };
      },
    },
    mounted() {
      this.showAllAff = sessionStorage.getItem('roleName') !== 'ADV_OPERATOR';

      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
      this.getSourceIdsList();
      this.getAmList();
    },
    watch: {
      listenChange: function() {
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        });
      },
    },
    methods: {
      ...mapActions('source', ['getSourceIdsList']),
      async getAmList() {
        try {
          const { result = [] } = await getAmListApi();
          this.amList = result.map((amName) => ({
            label: amName,
            value: amName,
          }));
        } catch (e) {
          console.error('getAmList error', e);
        }
      },
      searchListClick(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        let param = {
          ...this.directReport,
          ...this.pageParam,
        };

        if (Array.isArray(param.am) && param.am.length > 0) {
          param.am = param.am.join(',');
        } else {
          delete param.am;
        }
        if (Array.isArray(param.sourceIds) && param.sourceIds.length > 0) {
          param.sourceIds = param.sourceIds.join(',');
        } else {
          delete param.sourceIds;
        }

        if (this.offerChecked == true && param.groupMarks.indexOf('offerId') == -1) {
          param.groupMarks.push('offerId');
        } else if (this.offerChecked == false && param.groupMarks.indexOf('offerId') !== -1) {
          const offerIdIndex = param.groupMarks.findIndex((item) => {
            if (item == 'offerId') {
              return true;
            }
          });
          param.groupMarks.splice(offerIdIndex, 1);
        }

        if (this.amChecked == true && param.groupMarks.indexOf('amChecked') == -1) {
          param.groupMarks.push('am');
        } else if (this.amChecked == false && param.groupMarks.indexOf('amChecked') !== -1) {
          const amIndex = param.groupMarks.findIndex((item) => {
            if (item == 'am') {
              return true;
            }
          });
          param.groupMarks.splice(amIndex, 1);
        }
        if (this.sourceChecked == true && param.groupMarks.indexOf('sourceId') == -1) {
          param.groupMarks.push('sourceId');
        } else if (this.sourceChecked == false && param.groupMarks.indexOf('sourceId') !== -1) {
          const sourceIdIndex = param.groupMarks.findIndex((item) => {
            if (item == 'sourceId') {
              return true;
            }
          });
          param.groupMarks.splice(sourceIdIndex, 1);
        }

        if (this.countriesChecked == true && param.groupMarks.indexOf('geo') == -1) {
          param.groupMarks.push('geo');
        } else if (this.countriesChecked == false && param.groupMarks.indexOf('geo') !== -1) {
          const countryIndex = param.groupMarks.findIndex((item) => {
            if (item == 'geo') {
              return true;
            }
          });
          param.groupMarks.splice(countryIndex, 1);
        }

        if (this.packageChecked == true && param.groupMarks.indexOf('pkg') == -1) {
          param.groupMarks.push('pkg');
        } else if (this.packageChecked == false && param.groupMarks.indexOf('pkg') !== -1) {
          const prodIndex = param.groupMarks.findIndex((item) => {
            if (item == 'pkg') {
              return true;
            }
          });
          param.groupMarks.splice(prodIndex, 1);
        }

        if (this.monthChecked == true && param.groupMarks.indexOf('month') == -1) {
          param.groupMarks.push('month');
        } else if (this.monthChecked == false && param.groupMarks.indexOf('month') !== -1) {
          const monthIndex = param.groupMarks.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          param.groupMarks.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && param.groupMarks.indexOf('date') == -1) {
          param.groupMarks.push('date');
        } else if (this.dateChecked == false && param.groupMarks.indexOf('date') !== -1) {
          const dateIndex = param.groupMarks.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          param.groupMarks.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && param.groupMarks.indexOf('hour') == -1) {
          param.groupMarks.push('hour');
        } else if (this.hourChecked == false && param.groupMarks.indexOf('hour') !== -1) {
          const hourIndex = param.groupMarks.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          param.groupMarks.splice(hourIndex, 1);
        }

        if (this.startToEndDate?.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param.dateStart = startDate;
          param.dateEnd = endDate;
        }
        if (this.pdStartToEndDate?.length === 2) {
          let startDate = this.pdStartToEndDate[0];
          let endDate = this.pdStartToEndDate[1];
          param.pbDateStart = startDate;
          param.pbDateEnd = endDate;
        }

        this.pageParam.page = 1;

        const dataParam = cloneDeep(param);
        if (Array.isArray(dataParam.groupMarks)) {
          dataParam.groupMarks = dataParam.groupMarks.join(',');
        }
        this.dataParam = dataParam;
        this.getDirectReport();
      },
      getDirectReport() {
        this.listLoading = true;
        let param = {
          ...this.dataParam,
          ...this.pageParam,
        };
        param = filterObject(param);

        getDirectReport(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            const result = response.result?.data || [];
            const rejectReportList = result?.map((item) => {
              if (item.affiliateTrafficTag) {
                const arr = item.affiliateTrafficTag.split('-');

                item.affColor =
                  arr[0] === 'red' ? '#FF5722' : arr[0] === 'green' ? '#5F9EA0' : '#D8CAAF';

                item.affiliateTrafficTag = arr[1];
              }
              if (item.affSubTrafficTag) {
                const arr = item.affSubTrafficTag.split('-');
                item.affSubColor =
                  arr[0] === 'red' ? '#FF5722' : arr[0] === 'green' ? '#5F9EA0' : '#D8CAAF';
                item.affSubTrafficTag = arr[1];
              }
              return item;
            });
            this.directReportList = rejectReportList;
            this.total = response.result?.total;

            if (response.result?.totalLine) {
              let totalObject = new Object();
              totalObject.pkg = 'Total';
              totalObject.runningWaterPo = response.result.totalLine;
              this.directReportList.push(totalObject);
            }
          } else {
            this.directReportList = [];
            this.$message.error('Error: ' + response.message);
          }
        });
      },
      exportExcelFileClick() {
        let param = {};
        let groupMarks = new Array();

        if (this.amChecked == true && groupMarks.indexOf('amChecked') == -1) {
          groupMarks.push('am');
        } else if (this.amChecked == false && param.groupMarks.indexOf('amChecked') !== -1) {
          const amIndex = param.groupMarks.findIndex((item) => {
            if (item == 'am') {
              return true;
            }
          });
          param.groupMarks.splice(amIndex, 1);
        }
        if (this.sourceChecked == true && groupMarks.indexOf('sourceId') == -1) {
          groupMarks.push('sourceId');
        } else if (this.sourceChecked == false && groupMarks.indexOf('sourceId') !== -1) {
          const sourceIdIndex = groupMarks.findIndex((item) => {
            if (item == 'sourceId') {
              return true;
            }
          });
          groupMarks.splice(sourceIdIndex, 1);
        }

        if (this.sourceChecked == true && groupMarks.indexOf('sourceName') == -1) {
          groupMarks.push('sourceName');
        } else if (this.sourceChecked == false && groupMarks.indexOf('sourceName') !== -1) {
          const sourceNameIndex = groupMarks.findIndex((item) => {
            if (item == 'sourceName') {
              return true;
            }
          });
          groupMarks.splice(sourceNameIndex, 1);
        }

        if (this.offerChecked == true && groupMarks.indexOf('offerId') == -1) {
          groupMarks.push('offerId');
        } else if (this.offerChecked == false && groupMarks.indexOf('offerId') !== -1) {
          const offerIdIndex = groupMarks.findIndex((item) => {
            if (item == 'offerId') {
              return true;
            }
          });
          groupMarks.splice(offerIdIndex, 1);
        }

        if (this.countriesChecked == true && groupMarks.indexOf('geo') == -1) {
          groupMarks.push('geo');
        } else if (this.countriesChecked == false && groupMarks.indexOf('geo') !== -1) {
          const geoIndex = groupMarks.findIndex((item) => {
            if (item == 'geo') {
              return true;
            }
          });
          groupMarks.splice(geoIndex, 1);
        }

        if (this.packageChecked == true && groupMarks.indexOf('pkg') == -1) {
          groupMarks.push('pkg');
        } else if (this.packageChecked == false && groupMarks.indexOf('pkg') !== -1) {
          const pkgIndex = groupMarks.findIndex((item) => {
            if (item == 'pkg') {
              return true;
            }
          });
          groupMarks.splice(pkgIndex, 1);
        }

        if (this.monthChecked == true && groupMarks.indexOf('month') == -1) {
          groupMarks.push('month');
        } else if (this.monthChecked == false && groupMarks.indexOf('month') !== -1) {
          const monthIndex = groupMarks.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          groupMarks.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && groupMarks.indexOf('date') == -1) {
          groupMarks.push('date');
        } else if (this.dateChecked == false && groupMarks.indexOf('date') !== -1) {
          const dateIndex = groupMarks.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          groupMarks.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && groupMarks.indexOf('hour') == -1) {
          groupMarks.push('hour');
        } else if (this.hourChecked == false && groupMarks.indexOf('hour') !== -1) {
          const hourIndex = groupMarks.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          groupMarks.splice(hourIndex, 1);
        }

        param['dateStart'] = this.startToEndDate[0];
        param['dateEnd'] = this.startToEndDate[1];

        if (this.pdStartToEndDate?.length === 2) {
          let startDate = this.pdStartToEndDate[0];
          let endDate = this.pdStartToEndDate[1];
          param.pbDateStart = startDate;
          param.pbDateEnd = endDate;
        }
        if (this.directReport.timezone != null) {
          param['timezone'] = this.directReport.timezone;
        }

        if (this.directReport.sourceIds != null && this.directReport.sourceIds.length > 0) {
          param['sourceIds'] = this.directReport.sourceIds + '';
        }

        if (this.directReport.offerIds != null) {
          param['offerIds'] = this.directReport.offerIds;
        }
        if (this.directReport.geo != null) {
          param['geo'] = this.directReport.geo;
        }

        if (this.directReport.pkgList != null) {
          param['pkgList'] = this.directReport.pkgList;
        }
        if (groupMarks != null) {
          param['groupMarks'] = groupMarks.join(',');
        }
        if (this.directReport.offerName != null) {
          param['offerName'] = encodeURI(this.directReport.offerName);
        }
        if (this.directReport.isAllAff != null) {
          param['isAllAff'] = this.directReport.isAllAff;
        }
        param = filterObject(param);
        let strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_DIRECT_REPORT_DETAIL}?${strParam}`, '_blank');
      },
      filterNull(val) {
        return val == null ? 0 : val;
      },
      showReportComparingItem(cur, last) {
        const pecent =
          cur == last
            ? '--'
            : 0 == last
            ? '100%'
            : new Number(Math.round(((cur - last) / last) * 100)) + '%';
        const color = cur > last ? 'green' : cur == last ? 'silver' : 'red';
        let result = cur;
        if (last != 0) {
          result += '<span style="color:' + color + '">(' + pecent + ')</span>';
        }
        return result;
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.getDirectReport();
      },
      offerdetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/offer/detail',
          query: {
            offerId: row.offerId,
          },
        });
        window.open(href, '_blank');
      },
      sourcedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/source/detail',
          query: {
            sourceId: row.sourceId,
          },
        });
        window.open(href, '_blank');
      },
    },
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
